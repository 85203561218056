const BOOKING_STATUS = {
    DRAFT: 0,
    ACTIVE: 1,
    PENDING_REVIEW: 2,
    IN_REVIEW: 3,
    REJECTED: 4,
    COMPLETED: 5,
    PENDING_PAYMENT: 6,
    ARCHIVED: 7,
};

const BOOKING_TYPE = {
    FACE_TO_FACE: 1,
    REMOTE: 2,
    ONLINE: 3,
};

const BOOKING_IN_CONTEXT_LOCATION_TYPE = {
    HOME: 1, // Particpant's home
    WORKPLACE: 2, // Participant's workplace
    NEUTRAL: 3, // Neutral place / doesn't matter
};

const ONLINE_TASK_REQUIRED_DEVICE = {
    DESKTOP: 1,
    MOBILE: 2,
};

const ONLINE_TASK_REQUIRED_FEATURE = {
    CAMERA: 1,
    MICROPHONE: 2,
};

const MESSAGE_TYPE = { // TODO
    IN_APP: 1, // messages between 2 users
    SYSTEM: 2, // reminders, confirmation requests
    BATCH: 3, // batch messages
    SMS_VERIFICATION: 4, // verification messages
    ADMIN: 5, // Askable communicating with users
};
const MESSAGE_DIRECTION = {
    OTHER: 0, // other or unknown - legacy only
    ASKABLE_TO_PARTICIPANT: 1,
    ASKABLE_TO_CLIENT: 2,
    CLIENT_TO_PARTICIPANT: 3,
    PARTICIPANT_TO_CLIENT: 4,
    ASKABLE_TO_OTHER: 5,
    PARTICIPANT_TO_ASKABLE: 6,
};
const MESSAGE_SMS_STATUS = {
    FAILED: 0,
    QUEUED: 1,
    SENT: 2, // sent to 3rd party provider
    DELIVERED: 3, // provider has reported successful delivery
};

const PHONE_VERIFICATION_TYPE = {
    SMS: 1,
    PHONE_CALL: 2,
};

const SUBMISSION_AGREEMENT_STATUS = {
    CONSENT_GIVEN: 1,
    CONSENT_DENIED: 2,
    LINK_SENT: 3,
    VIEWED_IN_PROVIDER: 4,
    DECLINED_IN_PROVIDER: 5,
    COMPLETED_IN_PROVIDER: 6,
  };

  const BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE = {
    ASKABLE_TERMS: 0,
    CUSTOM_AGREEMENT: 1,
  };

const HELP_URL = 'https://help.askable.com/en/collections/3599700';

export {
    BOOKING_STATUS,
    BOOKING_TYPE,
    BOOKING_IN_CONTEXT_LOCATION_TYPE,
    ONLINE_TASK_REQUIRED_DEVICE,
    ONLINE_TASK_REQUIRED_FEATURE,
    MESSAGE_TYPE,
    MESSAGE_DIRECTION,
    MESSAGE_SMS_STATUS,
    PHONE_VERIFICATION_TYPE,
    SUBMISSION_AGREEMENT_STATUS,
    BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE,
    HELP_URL
};

// TODO: Update with correct link once app is published
export const ANDROID_STORE_LINK = 'https://play.google.com/apps/testing/com.askable.askable'; // `https://play.google.com/store/apps/details?id=${process.env.NEXT_PUBLIC_PLAY_STORE_ID}`;
export const APPLE_STORE_LINK = 'https://testflight.apple.com/join/ws3pThMh'; // `https://apps.apple.com/au/app/id${process.env.NEXT_PUBLIC_APP_STORE_ID}`;